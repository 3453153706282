@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
  .libre-baskerville-regular-italic {
    @apply font-libre-baskerville font-normal italic;
  }
}



.navbar-fixed{
  background-color: rgba(194, 194, 194, 0.853);
}

/* .container-card-room:hover img{
  box-shadow: 0px 0px 10px rgb(103, 103, 103);
 transition: ease .5s all;
   
}
.container-card-room:hover div{
  box-shadow: 0px 0px 20px rgb(71, 71, 71);
  transition: ease .5s all;
}
 */
@keyframes zoomInOut {
  0%, 100% {
    transform: scale(1); /* Estado inicial y final: la imagen está a su tamaño normal */
  }
  50% {
    transform: scale(1.2); /* A la mitad de la animación, la imagen aumenta al 120% de su tamaño original */
  }
}

.image-zoom {
  animation: zoomInOut 25s infinite; /* Aplica la animación, dura 10 segundos y se repite infinitamente */
}
